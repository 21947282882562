import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="edited-tbody"
export default class extends Controller {
  connect() {
    function insertAfter(referenceNode, newNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }

    let i = 0;

    while (true) {
      var reference = document.getElementById(`expense-date-${i}`);
      var expense_item = document.getElementById(`expense-item-${i}`);
      var sum = document.getElementById(`sum-${i}`);

      if (reference == null) {
        var reference = document.getElementById(`role-${i}`);

        if (reference == null) {
          var reference = document.getElementById(`name-${i}`);

          if (reference == null) {
            var reference = document.getElementById(`tr-${i}`);

            if (reference === null) {
              break;
            } else {
              reference.insertBefore(expense_item, reference.getElementsByTagName('td')[0]);
              reference.insertBefore(sum, reference.getElementsByTagName('td')[0]);
              i += 1;
              continue;
            }
          }
        }
      }

      insertAfter(reference, expense_item);
      insertAfter(expense_item, sum);

      i += 1;
    };
    if (document.getElementsByTagName('table')[0].previousSibling.data.includes('>')) {
      document.getElementsByTagName('table')[0].previousSibling.remove();
    }
  }
}
