import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown-menu"
export default class extends Controller {
  connect() {
    this.element.onclick = function(e){
      e.stopPropagation();
    };
    document.getElementById('user').onchange = function(){
      document.getElementById('role').selectedIndex = 0;
    }
    document.getElementById('role').onchange = function(){
      document.getElementById('user').selectedIndex = 0;
    }
  }
}
